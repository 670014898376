import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import { store } from "redux/store";
import App from "app/app";
import { EventProvider } from "components/event-bus";
import ErrorBoundary from "components/error-boundary";

import "react-toastify/dist/ReactToastify.css";
import "app/index.css";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <EventProvider>
        <ErrorBoundary>
          <BrowserRouter>
            <App />
            <ToastContainer />
          </BrowserRouter>
        </ErrorBoundary>
      </EventProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
