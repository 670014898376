import React, { memo, useRef } from "react";
import { TailSpin } from "react-loader-spinner";

import { LOADING_STATUSES as STATUS } from "constants/common";
import useChat from "hooks/use-chat";
import useAutoWrite from "hooks/use-auto-write";
import { formatDate } from "helpers/format-date";

const TicketChat = () => {
  const {
    status,
    chatMessages,
    isUserSentMessage,
    messageSendersNames,
    ticket,
    currentUser,
    handleKeyDown,
    handleChange,
    isChatEmpty,
    handleSubmitAndClose,
    handleSubmit,
    newChatMessage,
    isMessageProcessing,
  } = useChat();

  const textareaRef = useRef(null);
  const { setIsFocused } = useAutoWrite({
    textRef: textareaRef,
    textValue: newChatMessage,
    handleChange,
    disabled: isChatEmpty,
  });

  return (
    <div className="chatSection leftBorder">
      <div className="flexColumn yourTicketSectionHeader">
        <h2 className="rowSpaceBetween noMargin">
          {ticket?.attributes?.subject}
        </h2>
        <div className="yourTicketSectionSubHeader">
          {ticket?.attributes?.created_at &&
            formatDate(
              new Date(ticket?.attributes?.created_at),
              "yyyy-MM-dd H:mm"
            )}
        </div>
      </div>
      {status === STATUS.loading && !isMessageProcessing && (
        <div className="ticketLoaderBox">
          <TailSpin
            width={60}
            height={60}
            color="#E0E0E0"
            ariaLabel="loading"
          />
        </div>
      )}
      <div className="chatContainer" id="chatContainer">
        {(status !== STATUS.loading || isMessageProcessing) &&
          currentUser &&
          chatMessages.map((chatMessage) => (
            <div
              className={`chatMessageContainer ${
                isUserSentMessage(chatMessage) ? "chatMessageSent" : ""
              }`}
              key={chatMessage.id}
            >
              <div className="chatAuthor">
                {
                  messageSendersNames[
                    chatMessage.relationships.message_sender.data.id
                  ]
                }{" "}
                <span className="messageTimeStamp">
                  {formatDate(
                    chatMessage?.attributes?.created_at || "",
                    "yyyy-MM-dd H:mm"
                  )}
                </span>
              </div>
              <div
                className={`${
                  isUserSentMessage(chatMessage)
                    ? "chatBubble chatBubbleSent"
                    : "chatBubble"
                }`}
              >
                {chatMessage.attributes.body}
              </div>
            </div>
          ))}
      </div>
      <div
        className="chatInputContainer rowSpaceBetween"
        style={{ display: "flex" }}
      >
        <textarea
          ref={textareaRef}
          className="chatInput flexGrowOne"
          onKeyDown={(e) => handleKeyDown(e)}
          value={newChatMessage}
          onChange={(e) => handleChange(e.target.value)}
          disabled={isChatEmpty}
          onBlur={() => setIsFocused(false)}
        />
        <div className="chatButtons dontShrink rowEnd">
          <div className="buttonTertiary" onClick={handleSubmitAndClose}>
            Submit & Close
          </div>
          &nbsp;
          <div className="buttonPrimary" onClick={handleSubmit}>
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TicketChat);
