import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LOADING_STATUSES as STATUS } from "constants/common";
import { loadCurrentUser } from "redux/slices/current-user";
import {
  getAuthStatus,
  setLoginUserStatus,
  signInUser,
} from "redux/slices/auth";
import config from "config";

const Login = () => {
  const dispatch = useDispatch();

  const authStatus = useSelector(getAuthStatus);

  const [email, setEmail] = useState("");
  const [loginErrorShake, setLoginErrorShake] = useState("");
  const [password, setPassword] = useState("");
  const [twoAuthCode, setTwoAuthCode] = useState("");

  useEffect(() => {
    const expTime = localStorage.getItem("expired_time");

    if (
      localStorage.getItem("user_token_chat_frontend") &&
      expTime > new Date().getTime()
    ) {
      dispatch(setLoginUserStatus());
      dispatch(loadCurrentUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (authStatus === STATUS.failed) {
      setLoginErrorShake("shakeElement");

      const timer = setTimeout(() => {
        setLoginErrorShake("");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [dispatch, authStatus]);

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("grant_type", "password");
    formData.append("guard", config.guard);
    formData.append("one_time_password", twoAuthCode);
    formData.append("password", password);
    dispatch(signInUser(formData));
  };

  return (
    <div className="flexColumn flexGrowOne fixedWindowHeight">
      <div className={`loginContainer ${loginErrorShake}`}>
        <h2 style={{ lineHeight: "36px", textAlign: "left" }}>Muntra CS App</h2>
        <form onSubmit={handleLoginSubmit}>
          <div>
            <div className="flexColumn form-group has-feedback ">
              <label className="loginInputLabel">Email</label>
              <input
                className="form-control"
                name="email"
                onChange={({ target }) => setEmail(target.value)}
                type="email"
                value={email}
              />
            </div>

            <div className="flexColumn form-group has-feedback">
              <label className="loginInputLabel">Password</label>
              <input
                className="form-control"
                name="password"
                onChange={({ target }) => setPassword(target.value)}
                type="password"
                value={password}
              />
            </div>

            <div className="flexColumn form-group has-feedback">
              <label className="loginInputLabel">Authenticator code</label>
              <input
                className="form-control"
                name="two_factor_authentication_code"
                onChange={({ target }) => setTwoAuthCode(target.value)}
                type="text"
                value={twoAuthCode}
              />
            </div>
          </div>

          <button
            className="buttonPrimary primaryGraphicElement1 loginButton"
            type="submit"
          >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
