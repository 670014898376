import { BaseService } from "../base-service";

class AuthService extends BaseService {
  constructor() {
    super();
    this.userSignIn = this.userSignIn.bind(this);
  }

  async userSignIn(formData) {
    try {
      const response = await this.agent.post("/oauth/token", formData);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      throw error;
    }
  }
}

const ServiceInstance = new AuthService();

export default ServiceInstance;
export { ServiceInstance as AuthService };
