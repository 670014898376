import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import TicketsList from "components/tickets/tickets-list";

const Tickets = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    localStorage.setItem("latestView", pathname + search);
  }, [search, pathname]);

  return <TicketsList />;
};

export default Tickets;
