import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { LOADING_STATUSES as STATUS } from "constants/common";
import { SupportTicketsService } from "services/support-tickets-service";

export const loadTicket = createAsyncThunk("GET_TICKET", async (ticketId) => {
  const response = await SupportTicketsService.getTicketById(ticketId, {
    params: {
      messages_per_page: 100,
      include:
        "message_rooms.messages.message_sender.support_agent.default_user_image,message_rooms.messages.message_sender.user.default_user_image,responsible.default_user_image,user.default_user_image",
    },
  });

  return response?.data;
});

export const markTicketIsRead = createAsyncThunk(
  "MARK_TICKET_IS_READ",
  async (ticketId) => {
    const response = await SupportTicketsService.markTicketRead(ticketId);

    return response?.data;
  }
);

const initialState = {
  error: "",
  status: null,
  markAsReadStatus: null,
  ticket: {},
  userMessageInfo: [],
};

const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    setClosed: (state) => {
      state.ticket.attributes.closed_at = "now";
    },
    refreshMarkAsReadStatus: (state, action) => {
      state.markAsReadStatus = action.payload;
    },
  },
  extraReducers: {
    [loadTicket.pending]: (state) => {
      state.status = STATUS.loading;
    },
    [loadTicket.fulfilled]: (state, action) => {
      state.status = STATUS.success;
      state.ticket = action.payload.data;
      state.ticketMessagesInfo = action.payload.included;
    },
    [loadTicket.rejected]: (state, action) => {
      state.status = STATUS.success;
      state.error = action.payload;
    },
    [markTicketIsRead.pending]: (state) => {
      state.markAsReadStatus = STATUS.loading;
    },
    [markTicketIsRead.fulfilled]: (state) => {
      state.markAsReadStatus = STATUS.success;
    },
    [markTicketIsRead.rejected]: (state) => {
      state.markAsReadStatus = STATUS.failed;
    },
  },
});

export const { setClosed, refreshMarkAsReadStatus } = ticketSlice.actions;

export const getTicketData = (state) => state.ticket.ticket;
export const getTicketStatus = (state) => state.ticket.status;
export const getMarkAsReadStatus = (state) => state.ticket.markAsReadStatus;
export const getTicketMessagesInfo = (state) => state.ticket.ticketMessagesInfo;

export const getTicketUserObject = createSelector(
  getTicketMessagesInfo,
  (ticketMessagesInfo) =>
    ticketMessagesInfo?.find((infoItem) => infoItem.type === "user")
);

export const getTicketUserImage = createSelector(
  getTicketMessagesInfo,
  (ticketMessagesInfo) =>
    ticketMessagesInfo?.find((infoItem) => infoItem.type === "user_image")
);

export const getTicketSupportAgents = createSelector(
  getTicketMessagesInfo,
  (ticketMessagesInfo) =>
    ticketMessagesInfo?.filter((infoItem) => infoItem.type === "support_agent")
);

export const getTicketSupportAgentMessageSenders = createSelector(
  getTicketMessagesInfo,
  (ticketMessagesInfo) =>
    ticketMessagesInfo?.filter(
      (infoItem) =>
        infoItem.type === "message_sender" &&
        infoItem.relationships?.support_agent?.data?.id
    )
);

export const getTicketUserMessageSender = createSelector(
  getTicketMessagesInfo,
  getTicketUserObject,
  (ticketMessagesInfo, ticketUserObject) =>
    ticketMessagesInfo?.find(
      (infoItem) =>
        infoItem.type === "message_sender" &&
        ticketUserObject?.id === infoItem.relationships.user?.data?.id
    )
);

export const getTicketChatMessages = createSelector(
  getTicketMessagesInfo,
  (ticketMessagesInfo) =>
    ticketMessagesInfo?.filter((infoItem) => infoItem.type === "message") || []
);

export const getTicketMessageSendersNames = createSelector(
  getTicketUserMessageSender,
  getTicketUserObject,
  getTicketSupportAgents,
  getTicketSupportAgentMessageSenders,
  (
    ticketUserMessageSender,
    ticketUserObject,
    ticketSupportAgents,
    ticketSupportAgentMessageSenders
  ) => {
    let newMessageSendersNames = {};
    if (ticketUserMessageSender?.id) {
      newMessageSendersNames = {
        [ticketUserMessageSender.id]: `${ticketUserObject?.attributes?.first_name} ${ticketUserObject?.attributes?.last_name}`,
      };
    }

    if (ticketSupportAgentMessageSenders) {
      ticketSupportAgentMessageSenders?.forEach((supportAgent) => {
        const messageSenderObject = ticketSupportAgents.find(
          (userMessageInfoElement) =>
            userMessageInfoElement.id ===
            supportAgent.relationships.support_agent.data.id
        );
        newMessageSendersNames = {
          ...newMessageSendersNames,
          [supportAgent.id]: `${messageSenderObject.attributes.first_name} ${messageSenderObject.attributes.last_name}`,
        };
      });
    }

    return newMessageSendersNames;
  }
);

export const getTicketUserRoleName = createSelector(
  getTicketUserObject,
  (ticketUserObject) =>
    ticketUserObject?.attributes?.title ||
    ticketUserObject?.attributes?.role_name ||
    ""
);
export const getTicketUserName = createSelector(
  getTicketUserObject,
  (ticketUserObject) =>
    ticketUserObject &&
    `${ticketUserObject?.attributes?.first_name} ${ticketUserObject?.attributes?.last_name}`
);

export default ticketSlice.reducer;
