import { useEffect, useCallback, useMemo, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { LOADING_STATUSES as STATUS } from "constants/common";
import { MessagesService } from "services/messages-service";
import { SupportTicketsService } from "services/support-tickets-service";
import useTicket from "hooks/use-ticket";
import { SocketContext } from "contexts";
import {
  getCurrentUser,
  getCurrentUserSenderData,
} from "redux/slices/current-user";
import {
  getTicketData,
  getTicketStatus,
  getTicketMessagesInfo,
  getTicketUserImage,
  loadTicket,
  setClosed,
  getTicketChatMessages,
  getTicketMessageSendersNames,
  getTicketUserName,
  getTicketUserRoleName,
} from "redux/slices/ticket";
import { getOpenedTickets } from "redux/slices/tickets";
import { getIsWebsocketOpen } from "redux/slices/auth";
import ringer from "assets/audio/notification.mp3";

const useChat = () => {
  const audio = useMemo(() => new Audio(ringer), []);

  const { ticketId } = useParams();
  const dispatch = useDispatch();

  const { webSocketInstance } = useContext(SocketContext);
  const { removeOpenTicketAndChangeRoute, setTicketAgent, isAgentSet } =
    useTicket();

  const ticket = useSelector(getTicketData);
  const status = useSelector(getTicketStatus);
  const ticketMessagesInfo = useSelector(getTicketMessagesInfo);
  const ticketUserImage = useSelector(getTicketUserImage);
  const chatMessages = useSelector(getTicketChatMessages);
  const messageSendersNames = useSelector(getTicketMessageSendersNames);
  const userName = useSelector(getTicketUserName);
  const userRoleName = useSelector(getTicketUserRoleName);

  const currentUser = useSelector(getCurrentUser);
  const currentUserSenderData = useSelector(getCurrentUserSenderData);

  const openedTickets = useSelector(getOpenedTickets);
  const isWebsocketOpen = useSelector(getIsWebsocketOpen);

  const [newChatMessage, setNewChatMessage] = useState("");
  const [isChatEmpty, setIsChatEmpty] = useState(true);
  const [isMessageProcessing, setIsMessageProcessing] = useState(false);
  const [isTicketSubmitting, setIsTicketSubmitting] = useState(false);

  const currentOpenTicket = useMemo(
    () =>
      openedTickets.find((openTicket) => {
        return openTicket.id === ticketId;
      }),
    [openedTickets, ticketId]
  );

  const handleChange = useCallback((value) => {
    setNewChatMessage(value);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (isChatEmpty) {
      return;
    }
    if (status !== STATUS.loading && newChatMessage.trim() !== "") {
      await MessagesService.postSupportMessage({
        data: {
          attributes: { body: newChatMessage },
          relationships: {
            message_room: {
              data: {
                type: "message-rooms",
                id: ticket?.relationships?.message_rooms?.data[0]?.id,
              },
            },
          },
          type: "messages",
        },
      });

      if (webSocketInstance?.current?.readyState === WebSocket.OPEN) {
        webSocketInstance.current.send(
          JSON.stringify({
            room: ticket?.relationships?.message_rooms?.data[0]?.id,
            type: "sent-support-message",
          })
        );
      }
    }

    dispatch(loadTicket(ticketId));
    setNewChatMessage("");
    if (!isAgentSet) {
      setTicketAgent(currentUser.id);
    }
  }, [
    currentUser?.id,
    dispatch,
    isAgentSet,
    isChatEmpty,
    newChatMessage,
    setTicketAgent,
    status,
    ticket?.relationships?.message_rooms?.data,
    ticketId,
    webSocketInstance,
  ]);

  const handleKeyDown = useCallback(
    (e) => {
      e.target.style.height = "inherit";
      e.target.style.height = `${e.target.scrollHeight}px`;
      if (e.keyCode === 13 && !(e.ctrlKey || e.shiftKey || e.altKey)) {
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  const handleSubmitAndClose = useCallback(async () => {
    setIsTicketSubmitting(true);
    if (!isChatEmpty) {
      await handleSubmit();
    }

    dispatch(setClosed());
    await SupportTicketsService.closeTicket(ticket.id);

    setIsTicketSubmitting(false);
    removeOpenTicketAndChangeRoute(openedTickets.indexOf(currentOpenTicket));
  }, [
    currentOpenTicket,
    dispatch,
    handleSubmit,
    isChatEmpty,
    openedTickets,
    removeOpenTicketAndChangeRoute,
    ticket?.id,
  ]);

  const isUserSentMessage = useCallback(
    (chatMessage) => {
      return (
        currentUserSenderData &&
        currentUserSenderData[0]?.id ===
          chatMessage?.relationships.message_sender.data.id
      );
    },
    [currentUserSenderData]
  );

  useEffect(() => {
    const room = ticket?.relationships?.message_rooms?.data[0]?.id;
    if (
      isWebsocketOpen &&
      ticketMessagesInfo &&
      room &&
      ticketId === ticket.id
    ) {
      webSocketInstance.current.send(
        JSON.stringify({
          room: room.toString(),
          type: "entered-room",
        })
      );
    }
  }, [
    ticket?.id,
    ticket?.relationships?.message_rooms?.data,
    ticketId,
    ticketMessagesInfo,
    isWebsocketOpen,
    webSocketInstance,
  ]);

  useEffect(() => {
    if (!webSocketInstance.current || isTicketSubmitting) return;
    webSocketInstance.current.onmessage = (messageEvent) => {
      const message = JSON.parse(messageEvent.data);
      if (
        message.type === "sent-support-message" &&
        ticket &&
        ticket?.id === ticketId
      ) {
        setIsMessageProcessing(true);
        dispatch(loadTicket(ticketId));

        setTimeout(() => {
          setIsMessageProcessing(false);
        }, 1000);
      }
    };
  }, [
    ticket,
    webSocketInstance,
    isTicketSubmitting,
    ticketId,
    dispatch,
    audio,
  ]);

  useEffect(() => {
    const chatContainer = document.getElementById("chatContainer");
    if (chatContainer) chatContainer.scrollTop = chatContainer.scrollHeight;
  }, [chatMessages]);

  useEffect(() => {
    if (chatMessages.length === 0) {
      setIsChatEmpty(true);
      setNewChatMessage("Chat disabled. Invalid ticket");
    } else if (isChatEmpty) {
      setIsChatEmpty(false);
      setNewChatMessage("");
    }
  }, [isChatEmpty, chatMessages.length]);

  return {
    chatMessages,
    isUserSentMessage,
    messageSendersNames,
    ticket,
    currentUser,
    status,
    handleKeyDown,
    handleChange,
    isChatEmpty,
    handleSubmitAndClose,
    handleSubmit,
    newChatMessage,
    ticketUserImage,
    userName,
    userRoleName,
    isMessageProcessing,
  };
};

export default useChat;
