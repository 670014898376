import { format, utcToZonedTime } from "date-fns-tz";
import { sv } from "date-fns/locale";

export const formatDate = (date, dateFormat) => {
  const constructDate = new Date(date);
  const nyTimeZone = "Europe/Stockholm";

  const nyDate = utcToZonedTime(constructDate, nyTimeZone);

  if (!formatDate) return "";

  if (!dateFormat) {
    const nyDateWithoutFormat = utcToZonedTime(date, nyTimeZone);
    return format(nyDateWithoutFormat, "yyyy-MM-dd'T'HH:mm:ssXXX", {
      timeZone: "Europe/Stockholm",
      locale: sv,
    });
  }

  return format(nyDate, dateFormat, {
    timeZone: "Europe/Stockholm",
    locale: sv,
  });
};
