import React from "react";

const CloseIconSVG = () => (
  <svg
    className="closeIconSvg"
    viewBox="0 0 12 12"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path stroke="#000" strokeLinecap="round" d="M3 9l6-6m0 6L3 3" />
  </svg>
);

export default CloseIconSVG;
