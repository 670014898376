export const LOCALES = {
  english: "en-us",
  swedish: "sv-se",
  norwegian: "nb-no",
};

export const LOCALES_TITLES = {
  english: "English",
  swedish: "Swedish",
  norwegian: "Norwegian",
};
