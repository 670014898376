import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class SupportTicketsService extends BaseService {
  constructor(url, api) {
    super(url, api);
    this.getTickets = this.getTickets.bind(this);
    this.getTicketById = this.getTicketById.bind(this);
    this.closeTicket = this.closeTicket.bind(this);
    this.markTicketRead = this.markTicketRead.bind(this);
    this.patchTicket = this.patchTicket.bind(this);
  }

  async getTickets(params) {
    try {
      const response = await this.agent.get("", params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async getTicketById(id, params) {
    try {
      const response = await this.agent.get(`/${id}`, params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async closeTicket(id) {
    try {
      const response = await this.agent.post(`/${id}/close`);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async markTicketRead(id) {
    try {
      const response = await this.agent.post(`/${id}/mark-as-read`);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async patchTicket(id, data, params) {
    try {
      const response = await this.agent.patch(`/${id}`, data, params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new SupportTicketsService(API_ROUTES.supportTickets);

export default ServiceInstance;
export { ServiceInstance as SupportTicketsService };
