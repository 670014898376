import { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { SupportTicketsService } from "services/support-tickets-service";
import { SocketContext } from "contexts";
import { loadTicket, getTicketData } from "redux/slices/ticket";
import {
  getOpenedTickets,
  getTicketsData,
  removeOpenedTicket,
} from "redux/slices/tickets";
import { getCurrentUser } from "redux/slices/current-user";

const useTicket = () => {
  const { webSocketInstance } = useContext(SocketContext);
  const ticket = useSelector(getTicketData);
  const tickets = useSelector(getTicketsData);
  const openedTickets = useSelector(getOpenedTickets);
  const currentUser = useSelector(getCurrentUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const isAgentSet = ticket?.relationships?.responsible?.data !== null;

  const currentTicket = tickets?.data?.find(
    (ticket) => ticket.id === params.ticketId
  );

  const leaveRoom = useCallback(
    (openTicketIndex) => {
      const wsCurrent = webSocketInstance?.current;
      if (
        wsCurrent?.readyState === WebSocket.OPEN &&
        currentUser &&
        openedTickets[openTicketIndex]?.room
      ) {
        wsCurrent.send(
          JSON.stringify({
            room: openedTickets[openTicketIndex].room,
            type: "left-room",
          })
        );
      }
    },
    [currentUser, openedTickets, webSocketInstance]
  );

  const navigateToTicketsView = useCallback(() => {
    openedTickets.forEach((openTicket, index) => {
      if (openTicket.id === ticket.id) leaveRoom(index);
    });

    navigate(
      localStorage.getItem("latestView") ||
        `/tickets/view/1?page=1&sort_by=updated_at&order=asc&query=`
    );
  }, [leaveRoom, navigate, openedTickets, ticket?.id]);

  const removeOpenTicketAndChangeRoute = useCallback(
    (openTicketIndex) => {
      leaveRoom(openTicketIndex);

      if (openedTickets[openTicketIndex]?.id === currentTicket?.id) {
        if (openTicketIndex !== 0) {
          navigate(`/ticket/${openedTickets[openTicketIndex - 1]?.id}`);
        } else if (openedTickets.length !== 1) {
          navigate(`/ticket/${openedTickets[openTicketIndex + 1]?.id}`);
        }
      }
      if (openedTickets.length === 1) {
        navigateToTicketsView();
      }
      dispatch(removeOpenedTicket(openTicketIndex));
    },
    [
      currentTicket?.id,
      dispatch,
      leaveRoom,
      navigate,
      navigateToTicketsView,
      openedTickets,
    ]
  );

  const setTicketAgent = useCallback(
    async (agentId) => {
      await SupportTicketsService.patchTicket(ticket.id, {
        data: {
          attributes: {
            subject: ticket?.attributes?.subject,
          },
          relationships: {
            responsible: {
              data: {
                id: agentId,
                type: "support_agent",
              },
            },
          },
        },
      });
      dispatch(loadTicket(ticket.id));
    },
    [dispatch, ticket]
  );

  return {
    removeOpenTicketAndChangeRoute,
    navigateToTicketsView,
    setTicketAgent,
    isAgentSet,
  };
};

export default useTicket;
