import React from "react";

import useChat from "hooks/use-chat";
import AgentsDropdown from "../agents-dropdown";

const TicketSummary = () => {
  const { ticketUserImage, userName, userRoleName } = useChat();

  return (
    <div className="ticketSection">
      <div>
        <h3>Agent</h3>
        <AgentsDropdown />
        <h3>User</h3>
        <div className="user-summary" style={{ maxWidth: "270px" }}>
          {ticketUserImage && (
            <img
              src={ticketUserImage?.attributes?.small_thumbnail}
              alt="User avatar"
            />
          )}
          <div className="flexColumn">
            <span>{userName}</span>
            <span className="subtext" style={{ marginTop: "2px" }}>
              {userRoleName}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketSummary;
