import React, { useEffect, useCallback, useState, useRef } from "react";
import { useMount } from "react-use";
import { useSelector, useDispatch } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import ReactHtmlParser from "react-html-parser";
import clipboardCopy from "clipboard-copy";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";

import { LOADING_STATUSES as STATUS } from "constants/common";
import { QUERY_STATUSES } from "constants/ai";
import {
  getAiPromptsData,
  getAiPromptsStatus,
  getAiPromptsUI,
  loadAiPrompts,
  updateUI,
  reviewPrompt,
  setIntentClass,
} from "redux/slices/ai-prompts";
import { formatDate } from "helpers/format-date";
import SortIconSVG from "assets/svg/sort-icon";
import AngleDownSVG from "assets/svg/AngleDownSVG";
import Pagination from "components/pagination";
import CheckMark from "components/check-mark";
import Filter from "./components/filter";
import ContextTable from "./components/context-table";
import {
  tableHeader,
  namespaceTitle,
  namespaceAttribute,
  intentOptions,
} from "./assets";
import styles from "./styles.module.css";

const AiPrompts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const aiPrompts = useSelector(getAiPromptsData);
  const status = useSelector(getAiPromptsStatus);
  const { page, sortOrder, reviewStatus, hasContext, annotated } =
    useSelector(getAiPromptsUI);

  const queryPage = Number(searchParams.get("page"));
  const queryOrder = searchParams.get("order");
  const queryReviewStatus = searchParams.get("reviewed");
  const queryHasContext = searchParams.get("has-context");
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const optionsMenuRef = useRef(null);

  const handleSortByUpdatedAt = useCallback(() => {
    dispatch(updateUI({ sortOrder: sortOrder === "desc" ? "asc" : "desc" }));
  }, [dispatch, sortOrder]);

  const onPageChange = useCallback(
    (page) => {
      dispatch(updateUI({ page }));
    },
    [dispatch]
  );

  const onReadCompletion = useCallback(
    (id) => {
      dispatch(reviewPrompt(id)).then(() =>
        dispatch(
          loadAiPrompts({
            page,
            sortOrder,
            reviewStatus,
            hasContext,
            annotated,
          })
        )
      );
    },
    [annotated, dispatch, hasContext, page, reviewStatus, sortOrder]
  );

  const onCopyText = useCallback(
    (index, key) => {
      clipboardCopy(aiPrompts.data[index]?.attributes[key]).then(() => {
        toast.info("Text copied", {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
    },
    [aiPrompts]
  );

  const setIntent = useCallback(
    (id, value, intentClass) => {
      setSelectedPrompt(null);

      if (intentClass !== value) {
        dispatch(setIntentClass({ id, value })).then(() =>
          dispatch(
            loadAiPrompts({
              page,
              sortOrder,
              reviewStatus,
              hasContext,
              annotated,
            })
          )
        );
      }
    },
    [annotated, dispatch, hasContext, page, reviewStatus, sortOrder]
  );

  const toggleOptionsMenu = useCallback(
    (e, id) => {
      e.stopPropagation();
      setSelectedPrompt(selectedPrompt === id ? null : id);
    },
    [selectedPrompt]
  );

  const handleDocumentClick = useCallback(
    (event) => {
      if (
        optionsMenuRef.current &&
        !optionsMenuRef.current.contains(event.target)
      ) {
        setSelectedPrompt(null);
      }
    },
    [optionsMenuRef]
  );

  useMount(() => {
    const queryParams = {
      page: queryPage || page,
      sortOrder: queryOrder || sortOrder,
      reviewStatus:
        queryReviewStatus === null
          ? reviewStatus
          : QUERY_STATUSES[queryReviewStatus],
      hasContext:
        queryHasContext === null ? hasContext : QUERY_STATUSES[queryHasContext],
    };

    dispatch(updateUI(queryParams));
  });

  useEffect(() => {
    if (
      Number(queryPage) !== page ||
      queryOrder !== sortOrder ||
      QUERY_STATUSES[queryReviewStatus] !== reviewStatus ||
      QUERY_STATUSES[queryHasContext] !== hasContext
    ) {
      return;
    }

    dispatch(
      loadAiPrompts({ page, sortOrder, reviewStatus, hasContext, annotated })
    );
  }, [
    dispatch,
    page,
    sortOrder,
    hasContext,
    reviewStatus,
    queryPage,
    queryOrder,
    queryReviewStatus,
    queryHasContext,
    annotated,
  ]);

  useEffect(() => {
    navigate(
      `?page=${page}&order=${sortOrder}&reviewed=${reviewStatus}&has-context=${hasContext}`
    );
  }, [dispatch, navigate, hasContext, page, sortOrder, reviewStatus]);

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [handleDocumentClick]);

  return (
    <div className="aiPage" style={{ overflowY: "auto" }}>
      <div className="rowSpaceBetween">
        <h2 className="sectionHeader">Completions</h2>
      </div>
      <Filter />
      <div className="tableHeaderContainer">
        {tableHeader.map((item, index) => {
          return (
            <div
              style={{ ...item.styles }}
              className={styles.headerContainer}
              key={`${item.title}-${index}`}
            >
              <h3 className="flexRow noMargin">
                {item.title}
                {item.sortBtn && (
                  <button
                    onClick={handleSortByUpdatedAt}
                    className="ticketsSortBtn"
                  >
                    <SortIconSVG />
                  </button>
                )}
              </h3>
            </div>
          );
        })}
      </div>
      {status === STATUS.loading && (
        <div className="loader">
          <div className="loader-overlay">
            <TailSpin
              width={60}
              height={60}
              color="#E0E0E0"
              ariaLabel="loading"
            />
          </div>
        </div>
      )}
      <div className="tableListWrapper">
        {aiPrompts?.data?.map((prompt, index) => (
          <div className={`${styles.itemRow} parent`} key={prompt.id}>
            <div className={styles.itemData}>
              {prompt.attributes?.namespace && (
                <div className="badge gray-badge">
                  {namespaceAttribute[prompt.attributes.namespace] || ""}
                  <span className="tooltip">
                    {namespaceTitle[prompt.attributes.namespace]}
                  </span>
                </div>
              )}
            </div>
            <div className={styles.itemData}>
              <div
                className={styles.dotDotDotText}
                onClick={() => onCopyText(index, "question")}
              >
                {prompt.attributes?.question}
              </div>
              {!!prompt.attributes?.question?.trim().length && (
                <div className={styles.hint}>
                  {ReactHtmlParser(prompt.attributes?.question)}
                </div>
              )}
            </div>
            <div
              className={styles.itemData}
              onClick={(e) => toggleOptionsMenu(e, prompt.id)}
            >
              <div className={styles.intentContent}>
                <div>
                  {`
                    ${prompt.attributes?.intent_class?.charAt(0) || ""}
                    ${
                      prompt.attributes?.predicted_intent_class?.charAt(0)
                        ? `(${prompt.attributes?.predicted_intent_class?.charAt(
                            0
                          )})`
                        : ""
                    }
                  `}
                </div>
                <div>
                  <AngleDownSVG className={styles.dropDownAngleDownSVG} />
                </div>
              </div>
              {selectedPrompt === prompt.id && (
                <div className={styles.optionsMenu} ref={optionsMenuRef}>
                  {intentOptions.map((item) => (
                    <div
                      key={item.value}
                      onClick={() =>
                        setIntent(
                          prompt.id,
                          item.value,
                          prompt.attributes?.intent_class
                        )
                      }
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              )}
              {!!prompt.attributes?.predicted_intent_class?.trim().length && (
                <div className={styles.hint}>
                  {ReactHtmlParser(prompt.attributes?.predicted_intent_class)}
                </div>
              )}
            </div>
            <div className={styles.itemData}>
              <div
                className={styles.dotDotDotText}
                onClick={() => onCopyText(index, "context_search_text")}
              >
                {prompt.attributes?.context_search_text}&nbsp;
              </div>
              {!!prompt.attributes?.context_search_text?.trim().length && (
                <div className={styles.hint}>
                  {ReactHtmlParser(prompt.attributes?.context_search_text)}
                </div>
              )}
            </div>
            <div className={styles.itemData}>
              <div
                className={styles.dotDotDotText}
                onClick={() => onCopyText(index, "context")}
              >
                {prompt.attributes?.context}{" "}
              </div>
              {prompt.context_items.length ? (
                <div className={`${styles.hint} ${styles.large}`}>
                  <ContextTable contextItems={prompt.context_items} />
                </div>
              ) : (
                !!prompt.attributes?.context?.trim().length && (
                  <div className={styles.hint}>
                    {ReactHtmlParser(prompt.attributes?.context)}
                  </div>
                )
              )}
            </div>
            <div className={styles.itemData}>
              <div
                className={styles.dotDotDotText}
                onClick={() => onCopyText(index, "chat_history")}
              >
                {prompt.attributes?.chat_history}
              </div>
              {!!prompt.attributes?.chat_history?.trim().length && (
                <div className={styles.hint}>
                  {ReactHtmlParser(prompt.attributes?.chat_history)}
                </div>
              )}
            </div>
            <div className={styles.itemData}>
              <div
                className={styles.dotDotDotText}
                onClick={() => onCopyText(index, "answer")}
              >
                {prompt.attributes?.answer}
              </div>
              {prompt.attributes?.answer?.trim().length > 20 && (
                <div className={styles.hintRightAligned}>
                  {ReactHtmlParser(prompt.attributes?.answer)}
                </div>
              )}
            </div>
            <div className={styles.itemData}>
              <div
                className={styles.dotDotDotText}
                onClick={() => onCopyText(index, "prompt")}
              >
                {prompt.attributes?.prompt}
              </div>
              <div className={styles.hintRightAligned}>
                {ReactHtmlParser(prompt.attributes?.prompt)}
              </div>
            </div>
            <div className={styles.itemData}>
              {formatDate(prompt.attributes.timestamp || "", "yyyy-MM-dd H:mm")}
            </div>
            <div className={styles.itemData}>
              <CheckMark
                checked={Boolean(prompt.attributes?.reviewed_at)}
                onClick={() => onReadCompletion(prompt.id)}
              />
            </div>
          </div>
        ))}
      </div>
      <div>
        <Pagination
          current={aiPrompts?.meta?.pagination?.current_page}
          limit={10}
          onPageChanged={onPageChange}
          total={aiPrompts?.meta?.pagination?.total}
        />
      </div>
    </div>
  );
};

export default AiPrompts;
