export const filterOptions = [
  {
    key: "reviewStatus",
    options: [
      {
        title: "Reviewed",
        value: true,
      },
      {
        title: "Not reviewed",
        value: false,
      },
      {
        title: "Any",
        value: null,
      },
    ],
  },
  {
    key: "hasContext",
    options: [
      {
        title: "Has context",
        value: true,
      },
      {
        title: "Doesn't have context",
        value: false,
      },
      {
        title: "Any",
        value: null,
      },
    ],
  },
  {
    key: "annotated",
    options: [
      {
        title: "Annotated",
        value: true,
      },
      {
        title: "Not annotated",
        value: false,
      },
      {
        title: "Any",
        value: null,
      },
    ],
  },
];
