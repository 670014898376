import React, { memo, useCallback, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getCurrentUser } from "redux/slices/current-user";
import { getTicketsCount, loadTicketsCount } from "redux/slices/tickets";
import { menu } from "./assets";

const SideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { viewId } = useParams();
  const location = useLocation();
  const ticketsCount = useSelector(getTicketsCount);
  const currentUser = useSelector(getCurrentUser);

  const onNavClick = useCallback(
    (view) => {
      navigate(view.path);
    },
    [navigate]
  );

  useEffect(() => {
    if (currentUser?.id) dispatch(loadTicketsCount(currentUser?.id));
  }, [currentUser?.id, dispatch]);

  return (
    <div className="sideBar rightBorder">
      {menu.map(({ title, children }) => (
        <div key={title} style={{ marginBottom: "24px" }}>
          <h3 style={{ marginTop: "40px" }}>{title}</h3>
          {Object.keys(children).map((key) => {
            const view = children[key];
            const isActive =
              view.viewId === viewId || location.pathname.includes(view.viewId);

            return (
              <div
                key={view.viewId}
                className={`view ${isActive ? "active" : ""}`}
                role="button"
                onClick={() => onNavClick(view)}
              >
                <div className="rowSpaceBetween alignItemsCenter stretch">
                  <div>{view.title}</div>
                  {!!ticketsCount[key] && (
                    <div className="badge gray-badge">{ticketsCount[key]}</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default memo(SideBar);
