import axios from "axios";
import { addMinutes } from "date-fns";
import { toast } from "react-toastify";

import config from "config";
import { CUSTOM_EVENTS } from "constants/custom-events";
import { eventEmitter } from "components/event-bus";
import { handleResponseError } from "helpers/common";

export class BaseService {
  constructor(url = "", api = "") {
    this.baseServiceURL = api ? `${api}${url}` : `${config.api}${url}`;
    this.processError = this.processError.bind(this);
  }

  get baseURL() {
    return this.baseServiceURL;
  }

  get agent() {
    const headers = {};
    const accessToken = localStorage.getItem("user_token_chat_frontend");
    const expTime = localStorage.getItem("expired_time");

    if (accessToken) {
      headers.authorization = `Bearer ${accessToken}`;
    }

    const axiosInstance = axios.create({
      baseURL: this.baseURL,
      headers,
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        const expirationTime = addMinutes(new Date(), 120);
        localStorage.setItem("expired_time", expirationTime.getTime());
        return response;
      },
      (error) => {
        if (error.response && +error.response.status === 401) {
          eventEmitter.emit(CUSTOM_EVENTS.logout);
        } else throw error;
      }
    );

    axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("user_token_chat_frontend");
        if (expTime && token && expTime < new Date().getTime()) {
          eventEmitter.emit(CUSTOM_EVENTS.logout);
          return Promise.reject(new Error("The session has expired."));
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return axiosInstance;
  }

  // eslint-disable-next-line class-methods-use-this
  processError(error) {
    toast.error(handleResponseError(error), {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}
