import React, { memo, useCallback, useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import useTicket from "hooks/use-ticket";
import { useDetectOutsideClick } from "hooks/use-detect-outside-click";
import AngleDownSVG from "assets/svg/AngleDownSVG";
import { getTicketData } from "redux/slices/ticket";
import { getSupportAgents } from "redux/slices/support-agents";

const AgentsDropdown = () => {
  const dropdownRef = useRef(null);

  const ticket = useSelector(getTicketData);
  const supportAgents = useSelector(getSupportAgents);
  const { setTicketAgent } = useTicket();

  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  const selectedAgent = useMemo(
    () =>
      supportAgents?.find(
        (agent) => agent.id === ticket?.relationships?.responsible?.data?.id
      ),
    [supportAgents, ticket?.relationships?.responsible?.data?.id]
  );

  const handleToggleDropdown = useCallback(
    () => setIsActive(!isActive),
    [isActive, setIsActive]
  );

  const setAgentAndCloseDropdown = useCallback(
    (agentId) => {
      setIsActive(false);
      setTicketAgent(agentId);
    },
    [setIsActive, setTicketAgent]
  );

  return (
    <div className="menu-container">
      <button
        onClick={handleToggleDropdown}
        className="menu-trigger"
        style={{ width: "270px" }}
      >
        <span>
          {selectedAgent &&
            `${selectedAgent?.attributes.first_name} ${selectedAgent?.attributes?.last_name}`}
        </span>
        <AngleDownSVG className="dropDownAngleDownSVG" />
      </button>
      <nav
        ref={dropdownRef}
        className={`menu scrollable ${isActive ? "active" : "inactive"}`}
      >
        <ul>
          {supportAgents?.map((agent) => (
            <li key={agent.id}>
              <button onClick={() => setAgentAndCloseDropdown(agent.id)}>
                {`${agent?.attributes.first_name} ${agent?.attributes?.last_name}`}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default memo(AgentsDropdown);
