import { combineReducers } from "redux";

import agentsReducer from "./slices/support-agents";
import aiPromptsReducer from "./slices/ai-prompts";
import authReducer from "./slices/auth";
import currentUserReducer from "./slices/current-user";
import internalChatbotReducer from "./slices/internal-chatbot";
import ticketReducer from "./slices/ticket";
import ticketsReducer from "./slices/tickets";

const rootReducer = combineReducers({
  agents: agentsReducer,
  aiPrompts: aiPromptsReducer,
  auth: authReducer,
  currentUser: currentUserReducer,
  internalChat: internalChatbotReducer,
  ticket: ticketReducer,
  tickets: ticketsReducer,
});

export default rootReducer;
