import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LOADING_STATUSES as STATUS } from "constants/common";
import { CompletionsService } from "services/completions-service";
import { getItemFields } from "helpers/get-item-fields";
import { downloadFile } from "helpers/downloads";
import { createCompletionCSV } from "helpers/completions";
import config from "config";

export const loadAiPrompts = createAsyncThunk(
  "GET_AI_PROMPTS",
  async ({ page, sortOrder, reviewStatus, hasContext, annotated }) => {
    const params = {
      page,
      annotated,
      reviewed: reviewStatus,
      has_context: hasContext,
      sort_by: "timestamp",
      order: sortOrder === "desc" ? "desc" : "asc",
      per_page: 10,
      key: config.aiPromptsKey,
      include: "context_items.question",
    };
    const response = await CompletionsService.getCompletions({ params });

    const newData = response.data.data.map((item) => {
      const { relationships, ...rest } = item;
      const result = getItemFields(relationships, response.data.included);
      const sortedResult = result.context_items.sort(
        (a, b) => b.attributes.similarity - a.attributes.similarity
      );
      return { context_items: sortedResult, ...rest };
    });

    return { meta: response.data.meta[0], data: newData };
  }
);

export const reviewPrompt = createAsyncThunk("AI_PROMPT_REVIEW", async (id) => {
  const params = {
    key: config.aiPromptsKey,
  };
  const response = await CompletionsService.reviewCompletion(
    id,
    {},
    { params }
  );

  return response.data;
});

export const setIntentClass = createAsyncThunk(
  "SET_INTENT_CLASS",
  async ({ id, value }) => {
    const params = {
      intent_class: value,
      key: config.aiPromptsKey,
    };
    const response = await CompletionsService.annotateCompletion(
      id,
      {},
      { params }
    );

    return response?.data;
  }
);

export const downloadCompletionsCSV = createAsyncThunk(
  "LOAD_COMPLETIONS_CSV",
  async () => {
    const params = {
      page: 1,
      per_page: "10000",
      sort_by: "timestamp",
      order: "desc",
      key: config.aiPromptsKey,
    };
    const response = await CompletionsService.getCompletions({ params });
    const { data } = response.data;

    const csvData = createCompletionCSV(data);
    downloadFile("completions", csvData, "text/csv");
  }
);

const initialState = {
  error: "",
  status: null,
  aiPrompts: {},
  UI: {
    page: 1,
    sortOrder: "desc",
    reviewStatus: false,
    hasContext: null,
    annotated: false,
  },
};

const aiPromptsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    updateUI: (state, action) => {
      state.UI = { ...state.UI, ...action.payload };
    },
  },
  extraReducers: {
    [loadAiPrompts.pending]: (state) => {
      state.status = STATUS.loading;
    },
    [loadAiPrompts.fulfilled]: (state, action) => {
      state.status = STATUS.success;
      state.aiPrompts = action.payload;
    },
    [loadAiPrompts.rejected]: (state, action) => {
      state.status = STATUS.failed;
      state.error = action.payload;
    },
    [reviewPrompt.pending]: (state) => {
      state.status = STATUS.promptReviewLoading;
    },
    [reviewPrompt.fulfilled]: (state) => {
      state.status = STATUS.success;
    },
    [reviewPrompt.rejected]: (state) => {
      state.status = STATUS.failed;
    },
    [setIntentClass.pending]: (state) => {
      state.status = STATUS.setIntentLoading;
    },
    [setIntentClass.fulfilled]: (state) => {
      state.status = STATUS.success;
    },
    [setIntentClass.rejected]: (state) => {
      state.status = STATUS.failed;
    },
  },
});

export const { updateUI } = aiPromptsSlice.actions;

export const getAiPromptsData = (state) => state.aiPrompts.aiPrompts;
export const getAiPromptsError = (state) => state.aiPrompts.error;
export const getAiPromptsStatus = (state) => state.aiPrompts.status;
export const getAiPromptsUI = (state) => state.aiPrompts.UI;

export default aiPromptsSlice.reducer;
