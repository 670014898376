import { useCallback, useRef, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { addMinutes } from "date-fns";
import { toast } from "react-toastify";

import {
  loadSupportAgents,
  getSupportAgentsStatus,
} from "redux/slices/support-agents";
import { getCurrentUser, loadCurrentUser } from "redux/slices/current-user";
import { loadTicketsCount } from "redux/slices/tickets";
import config from "config";
import { uuidGenerator } from "helpers/uuid-generator";
import {
  getIsUserSignedIn,
  logoutUser,
  setLoginUserStatus,
  setIsWebsocketOpen,
} from "redux/slices/auth";
import ringer from "assets/audio/notification.mp3";

const useLogin = (webSocketInstance) => {
  const dispatch = useDispatch();
  const audio = useMemo(() => new Audio(ringer), []);

  const isUserSignedIn = useSelector(getIsUserSignedIn);

  const currentUser = useSelector(getCurrentUser);
  const supportAgentsStatus = useSelector(getSupportAgentsStatus);

  const uuid = useMemo(() => uuidGenerator(), []);
  const isSignedRef = useRef(isUserSignedIn);

  const createWebSocket = useCallback(() => {
    return new WebSocket(
      `${config.wsServer}/?client_id=${uuid}&support_agent_id=${currentUser?.id}`
    );
  }, [currentUser?.id, uuid]);

  const turnOffWebSocket = useCallback(() => {
    if (webSocketInstance.current) {
      webSocketInstance.current?.close();
      webSocketInstance.current = null;
    }
  }, [webSocketInstance]);

  const reconnectWebSocket = useCallback(async () => {
    if (
      webSocketInstance?.current?.readyState !== WebSocket.OPEN &&
      currentUser &&
      isUserSignedIn
    ) {
      try {
        const handleClose = (e) => {
          if (e.code !== 1000 && isSignedRef.current) {
            dispatch(setIsWebsocketOpen(false));
            setTimeout(() => reconnectWebSocket(), 3000);
          }
        };
        webSocketInstance.current = createWebSocket();
        webSocketInstance.current.addEventListener("close", handleClose);
        webSocketInstance.current.addEventListener("open", () => {
          setTimeout(() => dispatch(setIsWebsocketOpen(true)));
        });
        webSocketInstance.current.addEventListener("message", (event) => {
          const message = JSON.parse(event.data);
          if (message.type === "sent-support-message") {
            dispatch(loadTicketsCount(currentUser.id));
            if (audio) audio?.play();
            toast.info("New message!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("WebSocket connection error:", error);
      }
    }
  }, [
    dispatch,
    createWebSocket,
    currentUser,
    isUserSignedIn,
    webSocketInstance,
    audio,
  ]);

  useEffect(() => {
    if (isUserSignedIn && currentUser) {
      reconnectWebSocket();
    }

    isSignedRef.current = isUserSignedIn;
  }, [isUserSignedIn, currentUser, reconnectWebSocket, webSocketInstance]);

  const logoutAndCloseWebsocket = useCallback(() => {
    dispatch(logoutUser());
    turnOffWebSocket();
  }, [dispatch, turnOffWebSocket]);

  const logoutIfInvalidOrExpiredUser = useCallback(() => {
    const expTime = localStorage.getItem("expired_time");

    if (
      expTime < new Date().getTime() ||
      !localStorage.getItem("user_token_chat_frontend")
    ) {
      logoutAndCloseWebsocket();
    }
  }, [logoutAndCloseWebsocket]);

  const logInIfValidUser = useCallback(() => {
    const expTime = localStorage.getItem("expired_time");

    if (
      !currentUser &&
      isUserSignedIn &&
      localStorage.getItem("user_token_chat_frontend") &&
      expTime > new Date().getTime()
    ) {
      dispatch(setLoginUserStatus());
      dispatch(loadCurrentUser());
    }
  }, [currentUser, dispatch, isUserSignedIn]);

  const bindIncreaseExpirationOnAxiosCall = useCallback(() => {
    axios.interceptors.response.use(
      (response) => {
        const expirationTime = addMinutes(new Date(), 120);
        localStorage.setItem("expired_time", expirationTime.getTime());
        return response;
      },
      (error) => {
        if (error.response && +error.response.status === 401) {
          logoutAndCloseWebsocket();
        } else throw error;
      }
    );
  }, [logoutAndCloseWebsocket]);

  const bindCheckExpirationOnAxiosCall = useCallback(() => {
    const expTime = localStorage.getItem("expired_time");
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("user_token_chat_frontend");
        if (expTime && token && expTime < new Date().getTime()) {
          logoutAndCloseWebsocket();
          return Promise.reject(new Error("The session has expired."));
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }, [logoutAndCloseWebsocket]);

  const loadAgents = useCallback(() => {
    if (
      localStorage.getItem("user_token_chat_frontend") &&
      !supportAgentsStatus
    ) {
      dispatch(loadSupportAgents());
    }
  }, [dispatch, supportAgentsStatus]);

  return {
    logoutAndCloseWebsocket,
    loadAgents,
    logInIfValidUser,
    logoutIfInvalidOrExpiredUser,
    bindIncreaseExpirationOnAxiosCall,
    bindCheckExpirationOnAxiosCall,
  };
};

export default useLogin;
