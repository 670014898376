import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";

import { getIsUserSignedIn } from "redux/slices/auth";
import ViewsLayout from "components/views-layout";
import Login from "./login";
import Ticket from "./ticket";
import Tickets from "./tickets";
import AiPrompts from "./ai-prompts";
import InternalChatbot from "./internal-chatbot";

const AuthRoute = ({ children }) => {
  const isUserSignedIn = useSelector(getIsUserSignedIn);
  return !isUserSignedIn ? (
    children
  ) : (
    <Navigate to="/tickets/view/1?page=1&sort_by=updated_at&order=asc&query=" />
  );
};

const RequireAuthRoute = ({ children }) => {
  const isUserSignedIn = useSelector(getIsUserSignedIn);
  return !isUserSignedIn ? <Navigate to="/login" /> : children;
};

const PageRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/login"
        element={
          <AuthRoute>
            <Login />
          </AuthRoute>
        }
      />
      <Route element={<ViewsLayout />}>
        <Route
          exact
          path="/tickets/view/:viewId"
          element={
            <RequireAuthRoute>
              <Tickets />
            </RequireAuthRoute>
          }
        />
        <Route
          exact
          path="/ai-prompts"
          element={
            <RequireAuthRoute>
              <AiPrompts />
            </RequireAuthRoute>
          }
        />
        <Route
          exact
          path="/internal-chatbot"
          element={
            <RequireAuthRoute>
              <InternalChatbot />
            </RequireAuthRoute>
          }
        />
      </Route>
      <Route
        exact
        path="/ticket/:ticketId"
        element={
          <RequireAuthRoute>
            <Ticket />
          </RequireAuthRoute>
        }
      />
      <Route exact path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

AuthRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

RequireAuthRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default PageRoutes;
