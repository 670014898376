import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LOADING_STATUSES as STATUS } from "constants/common";
import { SupportAgentsService } from "services/support-agents-service";

export const loadCurrentUser = createAsyncThunk("auth/current", async () => {
  const response = await SupportAgentsService.getCurrentSupportAgent({
    params: {
      include: "message_sender",
    },
  });

  return response?.data;
});

const initialState = {
  errors: {},
  status: null,
  user: null,
  userSenderData: null,
};

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  extraReducers: {
    [loadCurrentUser.pending]: (state) => {
      state.status = STATUS.loading;
    },
    [loadCurrentUser.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = STATUS.success;
        state.user = action.payload.data;
        state.userSenderData = action.payload.included;
      } else {
        state.status = STATUS.failed;
      }
    },
    [loadCurrentUser.rejected]: (state, action) => {
      state.status = STATUS.failed;
      state.errors = action?.payload?.data;
    },
  },
});

export const getCurrentUser = (state) => state.currentUser.user;
export const getCurrentUserSenderData = (state) =>
  state.currentUser.userSenderData;
export const getCurrentErrors = (state) => state.currentUser.errors;
export const getCurrentUsersStatus = (state) => state.currentUser.status;

export default currentUserSlice.reducer;
