import React from "react";
import * as PropTypes from "prop-types";

import { tableHeader } from "./assets";
import styles from "./styles.module.css";

const ContextTable = ({ contextItems }) => {
  return (
    <>
      <div className="tableHeaderContainer no-margin">
        {tableHeader.map((item, index) => {
          return (
            <div
              style={{ ...item.styles }}
              className={styles.headerContainer}
              key={`${item.title}-${index}`}
            >
              <h4 className="flexRow noMargin">{item.title}</h4>
            </div>
          );
        })}
      </div>
      <div className="tableListWrapper no-margin">
        {contextItems?.map((item) => (
          <div className={styles.itemRow} key={item.id}>
            <div className={styles.itemData}>
              {item.relationships?.question?.data?.id}
            </div>
            <div className={styles.itemData}>{`${(
              (parseFloat(item.attributes?.similarity) || 0) * 100
            ).toFixed(1)}%`}</div>
            <div className={styles.itemData}>
              {item.relationships?.question?.data?.attributes?.embedded_text}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

ContextTable.propTypes = {
  contextItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      relationships: PropTypes.shape({
        question: PropTypes.shape({
          data: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            attributes: PropTypes.shape({
              embedded_text: PropTypes.string,
            }),
          }),
        }),
      }),
      attributes: PropTypes.shape({
        similarity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    })
  ),
};

ContextTable.defaultProps = {
  contextItems: [],
};
export default ContextTable;
