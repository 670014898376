import { BaseService } from "services/base-service";

import { API_ROUTES } from "constants/routes";

class MessagesService extends BaseService {
  constructor(url, api) {
    super(url, api);
    this.postSupportMessage = this.postSupportMessage.bind(this);
  }

  async postSupportMessage(data, params) {
    try {
      const response = await this.agent.post("/support", data, params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new MessagesService(API_ROUTES.messages);

export default ServiceInstance;
export { ServiceInstance as MessagesService };
