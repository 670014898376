export const tableHeader = [
  {
    title: "ID",
    styles: { width: "5%" },
  },
  {
    title: "Similarity",
    styles: { width: "7%" },
  },
  {
    title: "Embedded text",
    styles: { width: "88%" },
  },
];
