import {
  COMPLETIONS_HEADER,
  NAMESPACE_ATTRIBUTE as namespaceAttr,
} from "../constants/ai";

const csvItemFormatter = (value = "") => {
  if (typeof value === "string" && value.trim().length >= 1) {
    return `"${value.replace(/"/g, '""')}"`;
  }

  return '""';
};

export const createCompletionCSV = (data = []) => {
  const result = data.map((item) => {
    return [
      `"${namespaceAttr[item.attributes?.namespace?.charAt(0)] || ""}"`,
      csvItemFormatter(item.attributes?.question),
      `"${item.attributes?.predicted_intent_class?.charAt(0) || ""}"`,
      `"${item.attributes?.intent_class?.charAt(0) || ""}"`,
      csvItemFormatter(item.attributes?.context_search_text),
      csvItemFormatter(item.attributes?.context),
      csvItemFormatter(item.attributes?.chat_history),
      csvItemFormatter(item.attributes?.answer),
      csvItemFormatter(item.attributes?.prompt),
      `"${item.attributes?.timestamp || ""}"`,
      `"${item.attributes?.locale_tag || ""}"`,
      `"${item.attributes?.reviewed_at || ""}"`,
    ];
  });

  return [COMPLETIONS_HEADER, ...result].map((row) => row.join(",")).join("\n");
};
