export const downloadFile = (fileName, data, mimeType = "text/csv") => {
  const blob = new Blob([data], { type: mimeType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;
  link.target = "_blank";
  link.download = fileName;
  link.style.display = "none";
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};
