import React, { memo, useCallback } from "react";
import * as PropTypes from "prop-types";

const CheckMark = ({ onClick, checked }) => {
  const onMarkClick = useCallback(
    (e) => {
      if (checked) {
        return;
      }
      onClick(e);
    },
    [checked, onClick]
  );

  return (
    <div
      onClick={onMarkClick}
      className={`${
        checked ? "check-mark-checked" : ""
      } booking-listitem-check-mark check-mark-gray-foreground relative-position`}
    >
      <div className="check-mark-base enabled" role="button">
        <div className="relative-position">
          <svg
            className="check-mark-background blue-when-selected "
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            xmlns="https://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12.2" r="8.292" />
          </svg>
          <svg
            className="check-mark-foreground white-when-selected "
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            xmlns="https://www.w3.org/2000/svg"
          >
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

CheckMark.propTypes = {
  onClick: PropTypes.func,
  checked: PropTypes.bool,
};

CheckMark.defaultProps = {
  onClick: () => {},
};

export default memo(CheckMark);
