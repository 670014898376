import React, { memo, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { SocketContext } from "contexts";
import useTicket from "hooks/use-ticket";
import CloseIconSVG from "assets/svg/CloseIconSVG";
import LeftArrowSVG from "assets/svg/LeftArrowSVG";
import { addOpenedTicket, getOpenedTickets } from "redux/slices/tickets";
import { getTicketData } from "redux/slices/ticket";
import packageJson from "../../../package.json";

const Header = () => {
  const { removeOpenTicketAndChangeRoute, navigateToTicketsView } = useTicket();
  const openedTickets = useSelector(getOpenedTickets);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const ticket = useSelector(getTicketData);
  const { logoutAndCloseWebsocket } = useContext(SocketContext);

  useEffect(() => {
    if (params.ticketId && ticket && ticket.id === params.ticketId) {
      if (openedTickets?.length === 0) {
        dispatch(
          addOpenedTicket({
            id: params.ticketId,
            room: ticket?.relationships?.message_rooms?.data[0]?.id.toString(),
            subject: ticket?.attributes?.subject,
          })
        );
      } else
        for (
          let openTicketsIndex = 0;
          openTicketsIndex < openedTickets.length;
          openTicketsIndex += 1
        ) {
          if (openedTickets[openTicketsIndex].id === params.ticketId) {
            openTicketsIndex = openedTickets.length;
          } else if (openTicketsIndex === openedTickets.length - 1) {
            dispatch(
              addOpenedTicket({
                id: params.ticketId,
                room: ticket?.relationships?.message_rooms?.data[0]?.id.toString(),
                subject: ticket?.attributes.subject,
              })
            );
          }
        }
    }
  }, [dispatch, openedTickets, params.ticketId, ticket]);

  return (
    <div className="headerContainer">
      <button
        className="backButton"
        disabled={!params?.ticketId}
        onClick={navigateToTicketsView}
      >
        <LeftArrowSVG classProp={params?.ticketId ? "" : "invisibleSVG"} />
      </button>
      <div className="flexGrowOne openTicketsContainer">
        {openedTickets.map((openTicket, index) => (
          <div
            className={`openTicket rowSpaceBetween ${
              params.ticketId === openTicket.id ? "active" : ""
            }`}
            key={openTicket.id}
            style={{ alignItems: "center" }}
          >
            <div
              className="flexColumn flexGrowOne"
              onClick={() => navigate(`/ticket/${openTicket.id}`)}
              role="button"
            >
              <div className="openTicketSubject">{openTicket.subject}</div>
              <div className="openTicketId">#{openTicket.id}</div>
            </div>
            <button
              className="closeButton"
              onClick={() => removeOpenTicketAndChangeRoute(index)}
            >
              <CloseIconSVG classProp="SVGHover" />
            </button>
          </div>
        ))}
      </div>
      <div className="versionTextWrapper">
        <p>v{packageJson.version}</p>
      </div>
      <button
        className="ticketCategoryBox logoutButton"
        onClick={logoutAndCloseWebsocket}
      >
        Logout
      </button>
    </div>
  );
};

export default memo(Header);
