import React, { useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { CUSTOM_EVENTS } from "constants/custom-events";
import { eventEmitter } from "components/event-bus";
import Routes from "pages/routes";
import useLogin from "hooks/use-login";
import { getRandomIntOnRange } from "helpers/get-random-int-on-range";
import { getIsUserSignedIn } from "redux/slices/auth";
import { SocketContext } from "contexts";

import ringer from "assets/audio/notification.mp3";

const App = () => {
  const isUserSignedIn = useSelector(getIsUserSignedIn);
  const webSocketInstance = useRef(null);
  const wsCurrent = webSocketInstance?.current;
  const audio = useMemo(() => new Audio(ringer), []);

  const {
    logoutAndCloseWebsocket,
    loadAgents,
    logInIfValidUser,
    logoutIfInvalidOrExpiredUser,
  } = useLogin(webSocketInstance);

  const applyRandomBackgroundImage = () => {
    document.body.classList.add(`background${getRandomIntOnRange(1, 6)}`);
  };

  useEffect(() => {
    applyRandomBackgroundImage();
  }, []);

  useEffect(() => {
    if (isUserSignedIn) loadAgents();
  }, [loadAgents, isUserSignedIn]);

  useEffect(() => {
    logInIfValidUser();
  }, [logInIfValidUser]);

  useEffect(() => {
    logoutIfInvalidOrExpiredUser();
  }, [logoutIfInvalidOrExpiredUser]);

  useEffect(() => {
    eventEmitter.on(CUSTOM_EVENTS.logout, logoutAndCloseWebsocket);

    return () => {
      eventEmitter.off(CUSTOM_EVENTS.logout, logoutAndCloseWebsocket);
    };
  }, [logoutAndCloseWebsocket]);

  useEffect(() => {
    const handleMessage = (messageEvent) => {
      const message = JSON.parse(messageEvent.data);
      if (message.type === "new-ticket") {
        audio?.play();
        toast.info("New ticket!", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };

    wsCurrent?.addEventListener("message", handleMessage);

    return () => {
      if (wsCurrent) {
        wsCurrent.removeEventListener("message", handleMessage);
      }
    };
  }, [audio, wsCurrent]);

  const contextValue = useMemo(() => {
    return { webSocketInstance, logoutAndCloseWebsocket };
  }, [logoutAndCloseWebsocket]);

  return (
    <SocketContext.Provider value={contextValue}>
      <Routes />
    </SocketContext.Provider>
  );
};

export default App;
