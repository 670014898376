import React from "react";

const AngleDownSVG = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 640 640"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path
      d="M60.804 155.907c-14.197-13.807-36.886-13.476-50.67.697-13.82 14.185-13.488 36.874.697 50.682L296.2 484.01l24.992-25.69-25.004 25.796c14.244 13.807 36.98 13.453 50.8-.791.413-.402.78-.815 1.17-1.252l280.987-274.787c14.209-13.808 14.516-36.497.72-50.682-13.807-14.174-36.496-14.504-50.67-.697L321.089 408.3 60.805 155.907z"
      fillRule="nonzero"
    />
  </svg>
);

export default AngleDownSVG;
