const getIncludedItem = (included, search) =>
  included.find((item) => item.type === search.type && item.id === search.id);

const isObjectCondition = (item) => item?.data && typeof item.data === "object";

const isArrayCondition = (item) => item?.data && Array.isArray(item.data);

const operateObject = ({ result, key, included, relationships }) => {
  const includedItem = getIncludedItem(included, relationships[key].data);

  if (includedItem?.relationships) {
    const params = {
      result: includedItem,
      included,
      relationships: includedItem.relationships,
    };

    Object.keys(includedItem.relationships).forEach((key2) => {
      if (isArrayCondition(includedItem.relationships[key2])) {
        // eslint-disable-next-line no-use-before-define
        operateArray({ ...params, key: key2 });
      } else if (isObjectCondition(includedItem.relationships[key2])) {
        operateObject({ ...params, key: key2 });
      } else includedItem[key2] = undefined;
    });
  }

  result[key] = includedItem;
};

const operateArray = ({ result, key, included, relationships }) => {
  result[key] = relationships[key].data.map((item) => {
    const includedItem = getIncludedItem(included, item);
    if (includedItem.relationships) {
      const params = {
        result: includedItem,
        included,
        relationships: includedItem.relationships,
      };

      Object.keys(includedItem.relationships).forEach((key2) => {
        if (isArrayCondition(includedItem.relationships[key2])) {
          operateArray({ ...params, key: key2 });
        } else if (isObjectCondition(includedItem.relationships[key2])) {
          operateObject({ ...params, key: key2 });
        } else includedItem[key2] = undefined;
      });
    }
    return includedItem;
  });
};

export const getItemFields = (relationships, included) => {
  try {
    const result = {};
    const params = { result, included, relationships };

    Object.keys(relationships).forEach((key) => {
      if (isArrayCondition(relationships[key])) {
        operateArray({ ...params, key });
      } else if (isObjectCondition(relationships[key])) {
        operateObject({ ...params, key });
      } else result[key] = undefined;
    });

    return result;
  } catch (err) {
    throw new Error(err);
  }
};
