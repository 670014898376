import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getAiPromptsUI,
  updateUI,
  downloadCompletionsCSV,
} from "redux/slices/ai-prompts";
import { filterOptions } from "./assets";
import styles from "./styles.module.css";

const Filter = () => {
  const dispatch = useDispatch();

  const uiValues = useSelector(getAiPromptsUI);

  const onParamChange = useCallback(
    (value, key) => {
      dispatch(updateUI({ [key]: value, page: 1 }));
    },
    [dispatch]
  );

  const loadCompletionsCSV = useCallback(() => {
    dispatch(downloadCompletionsCSV());
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      {filterOptions.map(({ key, options }) => (
        <div key={key}>
          <div className={styles.buttonGroup}>
            {options.map(({ title, value }) => (
              <button
                className={
                  uiValues[key] === value ? styles.activeButton : styles.button
                }
                key={`${title}-${value}`}
                onClick={() => onParamChange(value, key)}
              >
                {title}
              </button>
            ))}
          </div>
        </div>
      ))}
      <button className={styles.loadButton} onClick={loadCompletionsCSV}>
        Export
      </button>
    </div>
  );
};

export default Filter;
