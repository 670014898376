import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { LOADING_STATUSES as STATUS } from "constants/common";
import {
  loadTicket,
  markTicketIsRead,
  getTicketData,
  getTicketStatus,
  refreshMarkAsReadStatus,
} from "redux/slices/ticket";
import TicketChat from "components/ticket/ticket-chat";
import Header from "components/header";
import TicketSummary from "components/ticket/ticket-summary";

const Ticket = () => {
  const [isClosed, setIsClosed] = useState(false);

  const status = useSelector(getTicketStatus);
  const ticket = useSelector(getTicketData);

  const { ticketId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (ticketId) {
      dispatch(markTicketIsRead(ticketId)).then(() => {
        dispatch(refreshMarkAsReadStatus(null));
      });
    }

    return () => {
      dispatch(markTicketIsRead(ticketId)).then(() => {
        dispatch(refreshMarkAsReadStatus(null));
      });
    };
  }, [dispatch, ticketId]);

  useEffect(() => {
    const ticketNotGotOrNewTicket = ticketId !== "undefined" && ticketId;
    if (ticketNotGotOrNewTicket) {
      dispatch(loadTicket(ticketId));
    }
  }, [dispatch, ticketId]);

  useEffect(() => {
    if (status === STATUS.success) {
      setIsClosed(ticket.attributes.closed_at);
    } else {
      setIsClosed(false);
    }
  }, [status, ticket]);

  return (
    <div className="flexColumn flexGrowOne fixedWindowHeight">
      <Header />
      <div className="ticketHeader">
        <div className="tickerHeaderButton">
          <span hidden={!isClosed} className="closed ticketStatus">
            Closed
          </span>
          Ticket #{ticketId}
        </div>
      </div>
      <div className="ticketContainer flexGrowOne">
        <TicketSummary />
        <TicketChat />
      </div>
    </div>
  );
};

export default Ticket;
