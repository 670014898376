import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as PropTypes from "prop-types";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";
const START_PAGE = "START";
const END_PAGE = "END";

const PAGE_NEIGHBORHOOD = 2;

const getRange = (from, to, step = 1) => {
  const range = [];

  for (let i = from; i <= to; i += step) range.push(i);

  return range;
};

const Pagination = ({ total, limit, current, onPageChanged }) => {
  const [pages, setPages] = useState([]);

  const pageLimit = useMemo(
    () => (typeof limit === "number" ? limit : 30),
    [limit]
  );
  const totalRecords = useMemo(
    () => (typeof total === "number" ? total : 0),
    [total]
  );
  const totalPages = useMemo(
    () => Math.ceil(totalRecords / pageLimit),
    [pageLimit, totalRecords]
  );

  const totalNumbers = useMemo(() => {
    if (global.innerWidth <= 330) return PAGE_NEIGHBORHOOD * 1 + 2;
    if (global.innerWidth > 330 && global.innerWidth <= 375)
      return PAGE_NEIGHBORHOOD * 2 + 2;
    if (global.innerWidth > 375) return PAGE_NEIGHBORHOOD * 2 + 3;
  }, []);

  const gotoPage = useCallback(
    (page) => {
      onPageChanged(page);
    },
    [onPageChanged]
  );

  const handleClick = useCallback(
    (page, e) => {
      e.preventDefault();
      gotoPage(page);
    },
    [gotoPage]
  );

  const handleMoveLeft = useCallback(
    (e) => {
      e.preventDefault();
      let calculatedPage = current - PAGE_NEIGHBORHOOD * 2 - 1;
      if (calculatedPage < 1) {
        calculatedPage = 1;
      }
      gotoPage(calculatedPage);
    },
    [current, gotoPage]
  );

  const handleMoveRight = useCallback(
    (e) => {
      e.preventDefault();
      let calculatedPage = current + PAGE_NEIGHBORHOOD * 2 + 1;
      if (calculatedPage > totalPages) {
        calculatedPage = totalPages;
      }
      gotoPage(calculatedPage);
    },
    [current, gotoPage, totalPages]
  );

  const handleMoveEnd = useCallback(
    (e) => {
      e.preventDefault();
      gotoPage(totalPages);
    },
    [gotoPage, totalPages]
  );

  const handleMoveStart = useCallback(
    (e) => {
      e.preventDefault();
      gotoPage(1);
    },
    [gotoPage]
  );

  const getPageNumbers = useCallback(() => {
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = current - PAGE_NEIGHBORHOOD;
      const rightBound = current + PAGE_NEIGHBORHOOD;
      const beforeLastPage = totalPages - 1;
      const startPage = leftBound > 3 ? leftBound : 2;
      const endPage =
        rightBound < beforeLastPage - 1 ? rightBound : beforeLastPage;

      pages = getRange(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const startSpillPage = START_PAGE;
      const endSpillPage = END_PAGE;
      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;
      if (leftSpill && !rightSpill) {
        const extraPages = getRange(
          startPage - singleSpillOffset,
          startPage - 1
        );
        pages = [startSpillPage, leftSpillPage, ...extraPages, ...pages];
        return [...pages, totalPages];
      }
      if (!leftSpill && rightSpill) {
        const extraPages = getRange(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages];
        return [1, ...pages, rightSpillPage, endSpillPage];
      }
      if (leftSpill && rightSpill) {
        return [
          startSpillPage,
          leftSpillPage,
          ...pages,
          rightSpillPage,
          endSpillPage,
        ];
      }
      return [1, ...pages, totalPages];
    }

    return getRange(1, totalPages);
  }, [current, totalNumbers, totalPages]);

  useEffect(() => {
    setPages(getPageNumbers());
  }, [getPageNumbers]);

  if (!totalRecords) return null;
  if (totalPages === 1) return null;

  return (
    <div className="flexJustCenter">
      {" "}
      <div className="paginationContentHolder">
        {pages.map((page) => {
          if (page === START_PAGE)
            return (
              <div key={page}>
                <span className="paginationContent" onClick={handleMoveStart}>
                  <span>&laquo;</span>
                </span>
              </div>
            );

          if (page === LEFT_PAGE)
            return (
              <div key={page}>
                <span className="paginationContent" onClick={handleMoveLeft}>
                  <span>&lsaquo;</span>
                </span>
              </div>
            );

          if (page === RIGHT_PAGE)
            return (
              <div key={page}>
                <span className="paginationContent" onClick={handleMoveRight}>
                  <span>&rsaquo;</span>
                </span>
              </div>
            );

          if (page === END_PAGE)
            return (
              <div key={page}>
                <span className="paginationContent" onClick={handleMoveEnd}>
                  <span>&raquo;</span>
                </span>
              </div>
            );

          return (
            <div
              key={page}
              className={`${current === page ? "activePaginationItem" : ""}`}
              style={{ marginBottom: "30px" }}
            >
              <span
                className="paginationContent"
                onClick={(e) => handleClick(page, e)}
              >
                {page}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Pagination.propTypes = {
  total: PropTypes.number,
  limit: PropTypes.number,
  current: PropTypes.number,
  onPageChanged: PropTypes.func,
};

Pagination.defaultProps = {
  total: null,
  limit: 10,
  current: 1,
  onPageChanged: (f) => f,
};

export default Pagination;
