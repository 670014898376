import { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { LOADING_STATUSES as STATUS } from "constants/common";
import { TICKETS_VIEWS } from "constants/tickets-views";
import { SocketContext } from "contexts";
import { getCurrentUser } from "redux/slices/current-user";
import { getTicketsStatus, loadTickets } from "redux/slices/tickets";
import { getMarkAsReadStatus } from "redux/slices/ticket";
import { useDebounce } from "hooks/use-debounce";
import { useQuery } from "hooks/use-query";

const useTickets = () => {
  const { webSocketInstance } = useContext(SocketContext);
  const currentUser = useSelector(getCurrentUser);
  const markAsReadStatus = useSelector(getMarkAsReadStatus);

  const status = useSelector(getTicketsStatus);

  const navigate = useNavigate();
  const { viewId } = useParams();
  const dispatch = useDispatch();
  const query = useQuery();

  const [lastViewId, setLastViewId] = useState(null);

  const [page, setPage] = useState(1);

  const [isSearchAction, setIsSearchAction] = useState(false);
  const [searchValue, setSearchValue] = useState(query.get("query") || "");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    query.get("query") || ""
  );

  const [isFilterAction, setIsFilterAction] = useState(false);
  const [sortBy, setSortBy] = useState(query.get("sort_by") || "updated_at");
  const [sortOrder, setSortOrder] = useState(query.get("order") || "asc");

  const currentView = useMemo(() => {
    let newView;
    Object.entries(TICKETS_VIEWS).every((view) => {
      const [key, value] = view;
      if (value.viewId === viewId) {
        newView = key;
        return false;
      }
      return true;
    });
    return newView;
  }, [viewId]);

  const refetchTickets = useCallback(
    (isSearchAction) => {
      if (localStorage.getItem("user_token_chat_frontend") && currentUser) {
        const getTicketObject = {
          page: isSearchAction ? 1 : +query.get("page"),
          responsibleIds: `${
            viewId === TICKETS_VIEWS.yourUnsolvedTickets.viewId
              ? currentUser?.id
              : ""
          }`,
          status: TICKETS_VIEWS[currentView]?.status,
          sortBy,
          sortOrder,
          query: debouncedSearchValue,
        };
        dispatch(loadTickets(getTicketObject));
      }
    },
    [
      currentView,
      debouncedSearchValue,
      dispatch,
      query,
      sortBy,
      sortOrder,
      currentUser,
      viewId,
    ]
  );

  const replaceSearchParams = useCallback(
    ({ customPage, customSortBy, customOrder }) => {
      navigate(
        `?page=${customPage || page}&sort_by=${customSortBy || sortBy}&order=${
          customOrder || sortOrder
        }&query=${searchValue}`
      );
    },
    [navigate, page, searchValue, sortBy, sortOrder]
  );

  const handleSearchDebounce = useCallback(() => {
    if (searchValue !== debouncedSearchValue) {
      setDebouncedSearchValue(searchValue);
      setIsSearchAction(true);
      replaceSearchParams({ customPage: 1 });
    }
  }, [debouncedSearchValue, replaceSearchParams, searchValue]);

  useDebounce(handleSearchDebounce, [], 700);

  const wsNewTicketEventHandler = useCallback(
    (event) => {
      const message = JSON.parse(event.data);
      if (
        message.type === "new-ticket" ||
        message.type === "sent-support-message"
      ) {
        refetchTickets(false);
      }
    },
    [refetchTickets]
  );

  useEffect(() => {
    webSocketInstance?.current?.addEventListener(
      "message",
      wsNewTicketEventHandler
    );

    const wsCurrent = webSocketInstance?.current;

    return () => {
      if (wsCurrent) {
        wsCurrent.removeEventListener("message", wsNewTicketEventHandler);
      }
    };
  }, [webSocketInstance, wsNewTicketEventHandler]);

  useEffect(() => {
    const currentPage = +query.get("page") || 1;
    const userToken = localStorage.getItem("user_token_chat_frontend");

    if (
      currentUser &&
      userToken &&
      (isFilterAction ||
        isSearchAction ||
        markAsReadStatus === STATUS.success ||
        (currentPage && currentPage !== +page && status !== STATUS.loading) ||
        (viewId !== lastViewId && status !== STATUS.loading))
    ) {
      setPage(isSearchAction ? 1 : currentPage);
      setLastViewId(viewId);

      setIsSearchAction(false);
      setIsFilterAction(false);

      refetchTickets(isSearchAction);
    }
  }, [
    isFilterAction,
    isSearchAction,
    lastViewId,
    page,
    query,
    refetchTickets,
    status,
    currentUser,
    viewId,
    markAsReadStatus,
  ]);

  return {
    currentView,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    setIsFilterAction,
    searchValue,
    setSearchValue,
    replaceSearchParams,
  };
};

export default useTickets;
