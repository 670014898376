import React, { memo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";

import { LOADING_STATUSES as STATUS } from "constants/common";
import { TICKETS_VIEWS } from "constants/tickets-views";
import useTickets from "hooks/use-tickets";
import { formatDate } from "helpers/format-date";
import SortIconSVG from "assets/svg/sort-icon";
import { getTicketsData, getTicketsStatus } from "redux/slices/tickets";
import Pagination from "../pagination";

const TicketsList = () => {
  const { viewId } = useParams();
  const navigate = useNavigate();

  const tickets = useSelector(getTicketsData);
  const status = useSelector(getTicketsStatus);

  const {
    currentView,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    setIsFilterAction,
    searchValue,
    setSearchValue,
    replaceSearchParams,
  } = useTickets();

  const handleSortByUpdatedAt = useCallback(() => {
    setIsFilterAction(true);
    if (sortBy !== "updated_at") {
      setSortBy("updated_at");
      setSortOrder("asc");
      replaceSearchParams({
        customSortBy: "updated_at",
        customOrder: "asc",
      });
    } else if (sortOrder === "asc") {
      setSortOrder("desc");
      replaceSearchParams({
        customOrder: "desc",
      });
    } else {
      setSortOrder("asc");
      replaceSearchParams({
        customOrder: "asc",
      });
    }
  }, [
    replaceSearchParams,
    setIsFilterAction,
    setSortBy,
    setSortOrder,
    sortBy,
    sortOrder,
  ]);

  const onPageChange = useCallback(
    (page) => {
      replaceSearchParams({ customPage: page });
    },
    [replaceSearchParams]
  );

  const getTableTicketStatus = useCallback(
    (closedAt) => {
      const isShownStatus = viewId === TICKETS_VIEWS.allTickets.viewId;
      if (!isShownStatus) return;

      return (
        <span className={`ticketStatus ${closedAt ? "closed" : "open"}`}>
          {closedAt ? "CLOSED" : "OPEN"}
        </span>
      );
    },
    [viewId]
  );

  const getTableAgentName = useCallback(
    (ticket) => {
      const { first_name: firstName = "", last_name: lastName = "" } =
        ticket.responsible?.attributes || {};
      return viewId === TICKETS_VIEWS.allOpenTickets.viewId ||
        viewId === TICKETS_VIEWS.allTickets.viewId
        ? `${firstName} ${lastName}`
        : "";
    },
    [viewId]
  );

  return (
    <div className="yourTicketsSection" style={{ overflowY: "auto" }}>
      <div className="rowSpaceBetween">
        <h2 className="sectionHeader">{TICKETS_VIEWS[currentView].title}</h2>
        <div className="tickets-search-container">
          <input
            className="tickets-search-input"
            name="search"
            onChange={({ target }) => setSearchValue(target.value)}
            value={searchValue}
            autoComplete="off"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="tableHeaderContainer">
        <div className="yourTicketsSectionReadContainer">
          <h3 className="noMargin yourTicketsSectionContentContainerHeader">
            &nbsp;
          </h3>
        </div>
        <div className="yourTicketsSectionStatusContainer">
          <h3 className="noMargin yourTicketsSectionContentContainerHeader">
            {viewId === TICKETS_VIEWS.allTickets.viewId ? "Status" : ""}
          </h3>
        </div>
        <div className="yourTicketsSectionRequesterContainer">
          <h3 className="noMargin yourTicketsSectionContentContainerHeader">
            User
          </h3>
        </div>
        <div className="yourTicketsSectionAgentNameContainer">
          <h3 className="noMargin yourTicketsSectionContentContainerHeader">
            {viewId === TICKETS_VIEWS.allTickets.viewId ||
            viewId === TICKETS_VIEWS.allOpenTickets.viewId
              ? "Agent"
              : ""}
          </h3>
        </div>
        <div className="yourTicketsSectionUpdatedAtContainer">
          <h3 className="noMargin yourTicketsSectionContentContainerHeader">
            Updated At{" "}
            <button onClick={handleSortByUpdatedAt} className="ticketsSortBtn">
              <SortIconSVG />
            </button>
          </h3>
        </div>
      </div>
      {status === STATUS.loading && (
        <div className="loader">
          <div className="loader-overlay">
            <TailSpin
              width={60}
              height={60}
              color="#E0E0E0"
              ariaLabel="loading"
            />
          </div>
        </div>
      )}
      <div className="ticketList">
        {tickets?.data?.map((ticket) => {
          const {
            first_name: firstName = "",
            last_name: lastName = "",
            role_name: roleName = "",
            title = "",
          } = ticket.user?.attributes || {};
          const fullName = `${firstName} ${lastName}`;
          const titleOrRoleName = title || roleName;
          const count = ticket.message_rooms[0]?.messages?.filter(
            (message) => !message.attributes.read_by_recipient_at
          )?.length;

          return (
            <div
              className="ticketListItem"
              key={ticket.id}
              onClick={() => navigate(`/ticket/${ticket.id}`)}
              role="button"
            >
              <div className="ticketListRead">
                <div className="statusWrapper">
                  {count ? (
                    <div className="badge small-badge red-badge">{count}</div>
                  ) : (
                    <span className="readText">Read</span>
                  )}
                </div>
              </div>
              <div className="ticketListItemStatus">
                {getTableTicketStatus(ticket.attributes.closed_at)}
              </div>
              <div className="ticketListItemRequester">
                <div>{fullName}&nbsp;</div>
                <div className="subtext">{titleOrRoleName}</div>
              </div>
              <div className="ticketListAgent">{getTableAgentName(ticket)}</div>
              <div className="ticketListItemUpdatedAt">
                {formatDate(
                  ticket.attributes.updated_at || "",
                  "yyyy-MM-dd H:mm"
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <Pagination
          current={tickets?.meta?.pagination?.current_page}
          limit={10}
          onPageChanged={onPageChange}
          total={tickets?.meta?.pagination?.total}
        />
      </div>
    </div>
  );
};

export default memo(TicketsList);
