import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LOADING_STATUSES as STATUS } from "constants/common";
import { SupportAgentsService } from "services/support-agents-service";

export const loadSupportAgents = createAsyncThunk("GET_AGENTS", async () => {
  const response = await SupportAgentsService.getSupportAgents();

  return response?.data;
});

const initialState = {
  error: "",
  status: null,
  agents: [],
};

const supportAgentsSlice = createSlice({
  name: "agents",
  initialState,
  extraReducers: {
    [loadSupportAgents.pending]: (state) => {
      state.status = STATUS.loading;
    },
    [loadSupportAgents.fulfilled]: (state, action) => {
      state.status = STATUS.success;
      state.agents = action.payload.data;
    },
    [loadSupportAgents.rejected]: (state, action) => {
      state.status = STATUS.failed;
      state.error = action.payload;
    },
  },
});

export const getSupportAgents = (state) => state.agents.agents;
export const getSupportAgentsStatus = (state) => state.agents.status;

export default supportAgentsSlice.reducer;
