import React from "react";
import { Outlet } from "react-router-dom";

import SideBar from "../side-bar";
import Header from "../header";

const ViewsLayout = () => {
  return (
    <div className="flexColumn flexGrowOne" style={{ overflowY: "clip" }}>
      <Header />
      <div
        className="flexGrowOne ticketsContainer"
        style={{ overflowY: "clip" }}
      >
        <SideBar />
        <Outlet />
      </div>
    </div>
  );
};

export default ViewsLayout;
