import { BaseService } from "services/base-service";

import config from "config";
import { API_ROUTES } from "constants/routes";

class CompletionsService extends BaseService {
  constructor(url, api) {
    super(url, api);
    this.getCompletions = this.getCompletions.bind(this);
    this.postCompletion = this.postCompletion.bind(this);
    this.reviewCompletion = this.reviewCompletion.bind(this);
    this.annotateCompletion = this.annotateCompletion.bind(this);
  }

  async getCompletions(params) {
    try {
      const response = await this.agent.get("", params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async postCompletion(data, params) {
    try {
      const response = await this.agent.post("", data, params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async reviewCompletion(id, data, params) {
    try {
      const response = await this.agent.post(`/${id}/review`, data, params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async annotateCompletion(id, data, params) {
    try {
      const response = await this.agent.post(`/${id}/annotate`, data, params);

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new CompletionsService(
  API_ROUTES.completions,
  config.aiPromptsApi
);

export default ServiceInstance;
export { ServiceInstance as CompletionsService };
