import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LOADING_STATUSES as STATUS } from "constants/common";
import { CompletionsService } from "services/completions-service";
import config from "config";

export const postCompletionAndGetResponse = createAsyncThunk(
  "POST_COMPLETION_AND_GET_RESPONSE",
  async ({ prompt, locale, uuid }) => {
    const response = await CompletionsService.postCompletion(
      {
        data: {
          type: "completion",
          attributes: {
            locale_tag: locale,
            prompt,
            message_room_id: uuid,
            internal: true,
          },
        },
      },
      {
        params: {
          key: config.aiPromptsKey,
        },
      }
    );
    return response?.data?.data;
  }
);

const initialState = {
  error: "",
  status: null,
  messages: [],
  chatMessage: "",
};

const internalChatSlice = createSlice({
  name: "internalChat",
  initialState,
  reducers: {
    updateChatMessage: (state, action) => {
      state.chatMessage = action.payload;
    },
    addChatMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    chatBotReset: () => initialState,
  },
  extraReducers: {
    [postCompletionAndGetResponse.pending]: (state) => {
      state.status = STATUS.loading;
    },
    [postCompletionAndGetResponse.fulfilled]: (state, action) => {
      state.status = STATUS.success;
      state.messages = [...state.messages, action.payload];
    },
    [postCompletionAndGetResponse.rejected]: (state, action) => {
      state.status = STATUS.failed;
      state.error = action.payload;
    },
  },
});

export const { updateChatMessage, addChatMessage, chatBotReset } =
  internalChatSlice.actions;

export const getInternalChatError = (state) => state.internalChat.error;
export const getInternalChatStatus = (state) => state.internalChat.status;
export const getChatMessage = (state) => state.internalChat.chatMessage;
export const getMessages = (state) => state.internalChat.messages;

export default internalChatSlice.reducer;
