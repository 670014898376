export const tableHeader = [
  {
    title: "",
    styles: { width: "4%" },
  },
  {
    title: "Question",
    styles: { width: "13%" },
  },
  {
    title: "Intent",
    styles: { width: "5%" },
  },
  {
    title: "Context search text",
    styles: { width: "10%" },
  },
  {
    title: "Context",
    styles: { width: "13%" },
  },
  {
    title: "Chat history",
    styles: { width: "13%" },
  },
  {
    title: "Answer",
    styles: { width: "13%" },
  },
  {
    styles: { width: "13%" },
    title: "Prompt",
  },
  {
    title: "Timestamp",
    styles: { width: "11%", display: "flex", justifyContent: "flex-end" },
    sortBtn: true,
  },
  {
    title: "",
    styles: { width: "5%" },
  },
];

export const namespaceAttribute = {
  internal: "I",
  external: "E",
};

export const namespaceTitle = {
  internal: "Internal",
  external: "External",
};

export const intentOptions = [
  { title: "Question", value: "question" },
  { title: "Search", value: "search" },
  { title: "Human support", value: "human_support" },
  { title: "Courtesy phrase", value: "courtesy_phrase" },
];
