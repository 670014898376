import { TICKETS_VIEWS } from "constants/tickets-views";
import { AI_VIEWS } from "constants/ai";

export const menu = [
  {
    title: "Ticket views",
    children: TICKETS_VIEWS,
  },
  {
    title: "AI",
    children: AI_VIEWS,
  },
];
