import { useEffect, useState, useCallback } from "react";

const useAutoWrite = ({ textRef, textValue, handleChange, disabled }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleGlobalKeyDown = useCallback(
    (e) => {
      if (
        e.key.length === 1 &&
        !isFocused &&
        !e.ctrlKey &&
        !e.altKey &&
        !e.metaKey &&
        !disabled
      ) {
        e.preventDefault();
        if (textRef.current) {
          textRef.current.focus();
          setIsFocused(true);
          handleChange(`${textValue}${e.key}`);
        }
      }
    },
    [disabled, handleChange, isFocused, textRef, textValue]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleGlobalKeyDown);

    return () => {
      window.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [handleGlobalKeyDown]);

  return { setIsFocused };
};

export default useAutoWrite;
