import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LOADING_STATUSES as STATUS } from "constants/common";
import { AuthService } from "services/auth-service";

export const signInUser = createAsyncThunk("auth/login", async (formData) => {
  const response = await AuthService.userSignIn(formData);

  return response?.data;
});

const initialState = {
  errors: {},
  status: null,
  user: null,
  userSignedIn: true,
  isWebsocketOpen: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutUser: (state) => {
      localStorage.removeItem("user_token_chat_frontend");
      localStorage.removeItem("expired_time");
      state.userSignedIn = false;
    },
    setLoginUserStatus: (state) => {
      state.userSignedIn = true;
    },
    setIsWebsocketOpen: (state, action) => {
      state.isWebsocketOpen = action.payload;
    },
  },
  extraReducers: {
    [signInUser.pending]: (state) => {
      state.status = STATUS.loading;
    },
    [signInUser.fulfilled]: (state, action) => {
      if (action.payload) {
        localStorage.setItem(
          "user_token_chat_frontend",
          action?.payload?.access_token
        );
        state.status = STATUS.success;
        state.user = action.payload;
        state.userSignedIn = true;
      } else {
        state.status = STATUS.failed;
      }
    },
    [signInUser.rejected]: (state, action) => {
      state.status = STATUS.failed;
      state.errors = action.payload;
    },
  },
});

export const getAuthUser = (state) => state.auth.user;
export const getAuthStatus = (state) => state.auth.status;
export const getAuthErrors = (state) => state.auth.errors;
export const getIsUserSignedIn = (state) => state.auth.userSignedIn;
export const getIsWebsocketOpen = (state) => state.auth.isWebsocketOpen;

export const { logoutUser, setLoginUserStatus, setIsWebsocketOpen } =
  authSlice.actions;

export default authSlice.reducer;
