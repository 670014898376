import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { ErrorNotificationsService } from "services/error-notifications-service";
import { formatDate } from "helpers/format-date";
import { name, version } from "../../../package.json";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    ErrorNotificationsService.sendErrorReport({
      date: formatDate(new Date()),
      message: error.message,
      name: error.name,
      stack: errorInfo.componentStack,
      url: document.location.href,
      version: `${name}: ${version}`,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError)
      return (
        <p style={{ marginTop: 40, marginLeft: 40 }}>Something went wrong.</p>
      );
    return children;
  }
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
