export const AI_NAV_KEYS = {
  aiPrompts: "ai-prompts",
  internalChatbot: "internal-chatbot",
};

export const AI_VIEWS = {
  PROMPTS: {
    viewId: AI_NAV_KEYS.aiPrompts,
    path: `/${AI_NAV_KEYS.aiPrompts}`,
    title: "Completions",
  },
  INTERNAL_CHATBOT: {
    viewId: AI_NAV_KEYS.internalChatbot,
    path: `/${AI_NAV_KEYS.internalChatbot}`,
    title: "Internal chatbot",
  },
};

export const QUERY_STATUSES = {
  true: true,
  false: false,
  null: null,
};

export const COMPLETIONS_HEADER = [
  '"Namespace"',
  '"Question"',
  '"Predicted intent"',
  '"Intent"',
  '"Context search text"',
  '"Context"',
  '"Chat history"',
  '"Answer"',
  '"Prompt"',
  '"Timestamp"',
  '"Locale"',
  '"Reviewed at"',
];

export const NAMESPACE_ATTRIBUTE = {
  i: "I",
  e: "E",
};
